<template>
  <div>
    <a-calendar>
      <template slot="dateCellRender" slot-scope="value">
        <div v-for="(item, index) in getData(value)" :key="index">
          {{ item }}
        </div>
      </template>
      <!-- <ul slot="dateCellRender" slot-scope="value" class="events">
        <li v-for="item in getListData(value)" :key="item.content">
          <a-badge :status="item.type" :text="item.content" />
        </li>
      </ul> -->
      <!-- <template slot="monthCellRender" slot-scope="value">
        <div v-if="getMonthData(value)" class="notes-month">
          <section>{{ getMonthData(value) }}</section>
          <span>Backlog number</span>
        </div>
      </template> -->
    </a-calendar>
  </div>
</template>

<script>
import { getCalendarList } from './api';
import moment from 'moment';
export default {
  data() {
    return {
      dayData: {},
    };
  },
  created() {
    this._getCalendarList();
  },
  methods: {
    async _getCalendarList() {
      try {
        const params = {
          year: new Date().getFullYear(),
        };
        let res = await getCalendarList(params);
        let data = {};
        res = res || [];
        res.forEach(({ data: resData }) => {
          resData = resData || [];

          resData.forEach(({ date, content }) => {
            data[date] = content;
          });
        });
        this.dayData = data;
      } catch (err) {
        console.error(err);
      }
    },
    getListData(value) {
      let listData;
      switch (value.date()) {
        case 8:
          listData = [
            { type: 'warning', content: 'This is warning event.' },
            { type: 'success', content: 'This is usual event.' },
          ];
          break;
        case 10:
          listData = [
            { type: 'warning', content: 'This is warning event.' },
            { type: 'success', content: 'This is usual event.' },
            { type: 'error', content: 'This is error event.' },
          ];
          break;
        case 15:
          listData = [
            { type: 'warning', content: 'This is warning event' },
            {
              type: 'success',
              content: 'This is very long usual event。。....',
            },
            { type: 'error', content: 'This is error event 1.' },
            { type: 'error', content: 'This is error event 2.' },
            { type: 'error', content: 'This is error event 3.' },
            { type: 'error', content: 'This is error event 4.' },
          ];
          break;
        default:
      }
      return listData || [];
    },

    getMonthData(value) {
      if (value.month() === 8) {
        return 1394;
      }
    },
    getData(day) {
      let key = moment(day).format('yyyy-MM-DD');
      //console.log('key', key);
      let arr = this.dayData[key];
      //console.log('this.dayData', this.dayData);
      return arr || [];
    },
  },
};
</script>

<style scoped lang="scss">
.events {
  list-style: none;
  margin: 0;
  padding: 0;
}
.events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}
.notes-month {
  text-align: center;
  font-size: 28px;
}
.notes-month section {
  font-size: 28px;
}
</style>
